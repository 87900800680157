<script>

import Layout from "../../../layouts/main.vue";
import axios from "axios";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      role: [],
      permission: {},
      name: '',
      price: ''
    };
  },
  computed: {
    permissionData() {
      return this.name + " " + this.price
    }

  },
  methods: {
    async getRoles() {
      await axios
        .get(
          "https://api.doctosoft.com/index.php/api/role/" + this.$route.params.id
        )
        .then((response) => {
          console.log(response.data);
          this.role = response.data;
          console.log(this.role.permission)
          this.permission = this.role.permission
          console.log(this.permission.name)
          this.name = this.permission.name
          this.price = this.permission.price
        });
    },

    async addRoles() {
      var urlencoded = new URLSearchParams();
      urlencoded.append("name", this.role.name);
      urlencoded.append("permission", JSON.stringify(this.role.permission));
      urlencoded.append("description", this.role.description);
      const result = await axios.put(
        "https://api.doctosoft.com/index.php/api/role/" + this.$route.params.id,
        urlencoded
      );

      this.msg = result.data;
      console.log(this.msg);

      this.$router.push("/admin/user-roles");
    },
  },
  created() {
    this.getRoles();
  },
};
</script>

<template>
  <Layout>
    <div class="back-btn">
      <div class="arrow">
        <button class="btn btn-light" @click="$router.push('/admin/user-roles')"><i
            class="mdi mdi-chevron-left"></i></button>
      </div>
      <div class="action-btn">
        <b-button class="btn-soft-info waves-effect waves-light"
          @click="$router.push('/admin/user-roles')">Cancel</b-button>
        <button class="btn  btn-md btn btn-primary" type="submit" @click="addRoles">Save</button>
      </div>
    </div>
    <b-row class="card-main">
      <b-col sm="6" xl="10">
        <b-card no-body>
          <form class="needs-validation" @submit.prevent="addRoles">
            <div class="mb-3">
              <label for="name" class="form-label">Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" v-model="role.name" placeholder="Enter name" />
            </div>

            <div class="mb-3">
              <label for="Permission" class="form-label">Permission <span class="text-danger">*</span></label>
              <input type="userPermission" class="form-control" v-model="permissionData" placeholder="Enter userName" />
            </div>

            <div class="mb-3">
              <label for="description" class="form-label">Description <span class="text-danger">*</span></label>
              <input type="description" class="form-control" v-model="role.description" placeholder="Enter description" />
            </div>

            <!-- <div class="mb-3">
            <label for="password" class="form-label"
              >Password <span class="text-danger">*</span></label
            >
            <input
              type="password"
              class="form-control"
              v-model="password"
              placeholder="Enter password"
            />
          </div>

    
          <div class="mb-3">
            <label for="role" class="form-label">Role</label>
            <select
              class="form-select"
              aria-label="size 3 select example"
              v-model="role"
            >
              <option v-for="role in roles" :key="role.id" :value="role.id">
                {{ role.name }}
              </option>
            </select>
          </div> -->
          </form>
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>